import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import {graphql} from 'gatsby'
import {HTMLContent} from '../components/Content'
import AboutPageTemplate from '../components/AboutPageTemplate'

const AboutPage = ({data}) => {
  const {markdownRemark: post} = data

  return (
    <div>
      <Helmet>
        <title>{post.frontmatter.meta_title}</title>
        <meta name='description' content={post.frontmatter.meta_description} />
        <body className={post.frontmatter.slug} />
      </Helmet>
      <AboutPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
        bg_hero={post.frontmatter.bg_hero}
        solo_image={post.frontmatter.solo_image}
        solo_image_1={post.frontmatter.solo_image_1}
        solo_image_2={post.frontmatter.solo_image_2}
      />
    </div>
  )
}

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default AboutPage

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        meta_title
        meta_description
        bg_hero
        slug
        solo_image
        solo_image_1
        solo_image_2
      }
    }
  }
`
